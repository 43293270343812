import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { IPublicPlan } from 'api/pricing-plans/types';

import { Typography } from 'wui/Typography';
import { Show } from 'wui/Show';

interface PricingPlanDetailsProps {
  plan: IPublicPlan;
}

export function PricingPlanDetails({ plan }: PricingPlanDetailsProps) {
  const { t } = useTranslation();

  if (!plan || !plan.translatedDetails) {
    return t('groups-web.discussion.feed.paid-post.preview.no-plan-info');
  }

  const { price } = plan.translatedDetails;

  return (
    <Typography variant="p2-16">
      {price}
      <Show if={Boolean(plan.buyerCanCancel)}>
        &nbsp;|&nbsp;
        {t('groups-web.discussion.feed.paid-post.preview.buyer-can-cancel')}
      </Show>
    </Typography>
  );
}

PricingPlanDetails.displayName = 'PricingPlanDetails';
