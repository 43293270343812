import React from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { groupsRequestJoinAGroup } from '@wix/bi-logger-groups/v2';

import { selectGroupPrivacyStatus } from 'store/selectors';
import { IFeedItem } from 'api/feed/types';

import {
  Info as InfoIcon,
  Pin as PinIcon,
  Price as PriceIcon,
} from '@wix/wix-ui-icons-common/on-stage';
import { IconProps } from '@wix/wix-ui-icons-common';
import { CardContent } from 'wui/CardContent';
import { Typography } from 'wui/Typography';
import { Box } from 'wui/Box';

import { GroupMembershipButton } from '../../GroupMembership';
import { Attachments } from './Attachments';

import { getAriaId } from '../a11y';

import classes from '../FeedItem.scss';

interface IProps {
  item: IFeedItem;
  isPinned: boolean;
  isSuggested: boolean;
  isRestrictedByPP: boolean;
}

type Label = {
  icon: React.FC<IconProps>;
  key: string;
};

export function Disclaimer({
  item,
  isPinned,
  isRestrictedByPP,
  isSuggested,
}: IProps) {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const feedItemId = item.feedItemId as string;
  const groupId = item.applicationContext?.contextId as string;
  const privacyStatus = useSelector(selectGroupPrivacyStatus(groupId));

  const label = getLabel();
  if (!label) {
    return null;
  }

  const { icon: IconComponent, key: labelKey } = label;

  return (
    <CardContent
      as="div"
      align="space-between"
      direction="horizontal"
      verticalAlign="middle"
      id={getAriaId(feedItemId, 'disclaimer')}
    >
      <Box gap="SP2" verticalAlign="middle">
        <IconComponent size={24} className={classes.icon} />
        <Typography className={classes.disclaimerText}>
          {t(labelKey)}
        </Typography>
      </Box>
      {getSecondaryAction()}
    </CardContent>
  );

  function getLabel(): Label | null {
    if (isPinned) {
      return {
        icon: PinIcon,
        key: 'groups-web.discussion.feed.pined-post',
      };
    }

    if (isSuggested) {
      return {
        icon: InfoIcon,
        key: isMobile
          ? 'groups-web.discussion.feed.mobile.suggested-group.post-label.info'
          : 'groups-web.discussion.feed.suggested-group.post-label.info',
      };
    }

    if (isRestrictedByPP) {
      return {
        icon: PriceIcon,
        key: 'groups-web.discussion.feed.paid-post.disclaimer',
      };
    }

    return null;
  }

  function getSecondaryAction() {
    if (isRestrictedByPP) {
      return <Attachments stats={item.mediaData} />;
    }
    if (isSuggested) {
      return (
        <GroupMembershipButton
          outlined
          redirect
          groupId={groupId}
          bi={groupsRequestJoinAGroup({
            group_id: groupId,
            origin: 'public_feed',
            type: (privacyStatus || '').toLowerCase(),
          })}
          size="tiny"
        />
      );
    }
    return null;
  }
}

Disclaimer.displayName = 'Disclaimer';
