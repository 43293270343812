import { createStylesParam, StyleParamType } from '@wix/tpa-settings';
import type { CustomCssVarsFn } from '@wix/yoshi-flow-editor';

import { getDisplayForCssVar } from 'common/utils/getDisplayForCssVar';

import * as common from 'settings/common';
import * as wui from 'settings/wui';
import * as feed from 'settings/feed';
import * as group from 'settings/group';

export default {
  ...common.styles,
  ...wui.styles,
  ...feed.styles,
  ...group.layout.styles,
  ...group.header.styles,

  showComments: createStylesParam('showComments', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
};

// noinspection JSUnusedGlobalSymbols
export const customCssVars: CustomCssVarsFn = ({ styleParams }) => {
  return {
    showReactionsBlock: getDisplayForCssVar(
      styleParams,
      'showReactions',
      'block',
    ),
    showCommentsBlock: getDisplayForCssVar(
      styleParams,
      'showComments',
      'block',
    ),
  };
};
