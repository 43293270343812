import React from 'react';
import { Tag as TagTPA, TagSize, TagSkin } from 'wix-ui-tpa/cssVars';

interface IProps
  extends Omit<React.ComponentProps<typeof TagTPA>, 'size' | 'skin'> {
  size?: 'small' | 'medium' | 'large';
  skin?: 'light' | 'solid';
}

export function Tag(props: IProps) {
  const { size, skin, ...rest } = props;

  return <TagTPA size={size as TagSize} skin={skin as TagSkin} {...rest} />;
}

Tag.displayName = 'wui/Tag';
