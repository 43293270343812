import loadable from '@wix/yoshi-flow-editor/loadable';

export const RichContentViewer = loadable(
  () => import(/* webpackChunkName: "RichContentViewer"*/ './Viewer'),
  {
    resolveComponent: (component) => component.RichContentViewer,
  },
);

export const RichContentEditor = loadable(
  () => import(/* webpackChunkName: "RichContentEditor"*/ './Editor'),
  {
    resolveComponent: (component) => component.RichContentEditor,
  },
);
