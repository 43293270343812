import React from 'react';
import cls from 'classnames';

import {
  IconToggle,
  type IconToggleProps,
  type IconToggleLabelPlacement,
} from 'wix-ui-tpa/cssVars';

import classes from './ToggleButton.scss';

interface IProps extends Omit<IconToggleProps, 'labelPlacement'> {
  labelPlacement?: 'start' | 'end';
}

export function ToggleButton(props: IProps) {
  const { labelPlacement, className, ...rest } = props;

  return (
    <IconToggle
      {...rest}
      labelPlacement={labelPlacement as IconToggleLabelPlacement}
      className={cls(classes.root, className, {
        [classes.checked]: props.checked,
      })}
    />
  );
}

ToggleButton.displayName = 'ToggleButton';
ToggleButton.defaultProps = {
  animation: true,
  labelPlacement: 'end',
};
