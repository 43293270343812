import React from 'react';

import {
  Like as LikeIcon,
  LikeHeart as LikeHeartIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import classes from './Reaction.scss';

interface IProps {
  code: string;
  reacted: boolean;
}

export function ReactionIcon(props: IProps) {
  const { code, reacted } = props;

  if (reacted) {
    return code;
  }

  switch (code) {
    case '❤️':
      return <LikeHeartIcon size={16} className={classes.icon} />;

    case '👍':
      return <LikeIcon size={16} className={classes.icon} />;

    default:
      return code;
  }
}

ReactionIcon.displayName = 'ReactionIcon';
